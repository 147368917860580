<template>
  <div>
    <el-button class="mb-32" @click="$router.push({ name: 'Products' })">Tilbage</el-button>

    <div class="sm:flex sm:items-center sm:justify-between mb-20 px-16 sm:px-0">
      <h1 class="font-medium text-lg sm:mb-0">Produkt {{ productData && productData.title }}</h1>

      <div class="flex flex-wrap">
        <el-button
          tag="button"
          :loading="buttonLoading"
          class="group mr-16 mt-12"
          @click="onViewInShop"
        >
          Vis på avisfordele.dk
        </el-button>

        <!--<el-button
          tag="button"
          type="primary"
          :loading="approveLoading"
          class="mt-12 mr-16"
          @click="onApprove"
        >
          Godkend
        </el-button>

        <el-button
          tag="button"
          type="danger"
          :loading="disapproveLoading"
          class="mt-12"
          @click="disapproveModal = true"
        >
          Afvis
        </el-button>-->
      </div>
    </div>

    <el-card class="mb-32 px-16 sm:px-24">
      <table-component
        v-if="productData"
        :product-data="productData"
        :is-loading="buttonLoading"
      />
    </el-card>

    <div class="flex items-center justify-end">
      <el-button>Annuller</el-button>
      <el-button type="indigo" class="ml-12">Gem</el-button>
    </div>

    <el-modal title="Comment" :visible="disapproveModal" @close="closeModal">
      <ValidationObserver ref="disapproveProduct" tag="div" class="sm:w-350 mt-20">
        <el-textarea
          v-model="comment"
          label="Comment"
          textarea-class="rounded w-full"
          textarea-name="comment"
          placeholder="Enter your comment"
          rules="required"
          :disabled="buttonLoading"
          class="w-full"
        />

        <el-button
          tag="button"
          type="danger"
          :loading="disapproveLoading"
          class="mt-12 ml-auto"
          @click="onDisapprove"
        >
          Afvis
        </el-button>
      </ValidationObserver>
    </el-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ProductsService from '@/services/products.service'

const ElCard = () => import('@/components/shared/ELCard')
const ElButton = () => import('@/components/shared/ELButton')
const ElModal = () => import('@/components/shared/ELModal')
const ElTextarea = () => import('@/components/shared/ELTextarea')
const TableComponent = () => import('@/components/product/Table')

export default {
  name: 'ProductPage',

  components: { ElCard, ElButton, ElModal, ElTextarea, TableComponent },

  data () {
    return {
      buttonLoading: false,
      approveLoading: false,
      disapproveLoading: false,
      productData: null,
      comment: '',
      disapproveModal: false
    }
  },

  computed: {
    getStatus () {
      const metafield = this.productData.data.metafields.find(el => el.namespace === 'dtails_pim')
      if (typeof metafield.value === 'string') metafield.value = JSON.parse(metafield.value)
      return metafield.value.workflowStatus
    }
  },

  created () {
    this.firstLoad()
  },

  methods: {
    ...mapActions(['loading', 'showNotification']),

    firstLoad () {
      const { productId } = this.$route.params

      ProductsService.getProduct(productId)
        .then(res => {
          res.data.metafields = res.data.metafields.map(el => {
            el.value = JSON.parse(el.value)
            return el
          })
          res.data.tags = res.data.tags.replace('category_', '')
          res.data.tags = res.data.tags.split(',')[0]
          this.productData = res
          this.loading(false)
        })
    },

    onViewInShop () {
      this.buttonLoading = true
      const { productId } = this.$route.params

      ProductsService.getViewInShopLink(productId)
        .then(res => {
          this.buttonLoading = false
          window.open(res.onlineStorePreviewUrl, '_blank')
        })
        .catch(() => {
          this.buttonLoading = false
        })
    },

    onApprove () {
      this.approveLoading = true
      const { productId } = this.$route.params

      ProductsService.approveProduct(productId)
        .then(() => {
          this.approveLoading = false
        })
        .catch(() => {
          this.approveLoading = false
        })
    },

    onDisapprove () {
      this.$refs.disapproveProduct.validate().then(isValid => {
        if (!isValid) {
          const errors = []

          // generate array of arrows
          Object.keys(this.$refs.disapproveProduct.errors).map(error => {
            errors.push(...this.$refs.disapproveProduct.errors[error])
          })

          this.showNotification({ type: 'error', message: errors })
          return false
        }

        this.disapproveLoading = true
        const { productId } = this.$route.params

        ProductsService.disapproveProduct(productId, { comment: this.comment, status: this.getStatus })
          .then(() => {
            this.disapproveLoading = false
            this.disapproveModal = false
          })
          .catch(() => {
            this.disapproveLoading = false
          })
      })
    },

    closeModal () {
      this.disapproveModal = false
    }
  }
}
</script>>
