import $axios from '@/core/api.config'
import { generateRequestParams } from '@/core/helper-functions'

class ProductsService {
  // get list of products
  getProductsList (params = {}) {
    return $axios.get('/adv/products', {
      params: generateRequestParams(params)
    })
  }

  // get product by id
  getProduct (id) {
    return $axios.get(`/adv/products/${id}`)
  }

  // getViewSHopLink
  getViewInShopLink (id) {
    return $axios.get(`/adv/products/${id}/getPreviewUrl`)
  }

  // approve product
  approveProduct (productId) {
    return $axios.put(`/adv/products/${productId}/sendStatusUpdate`)
  }

  // disapprove product
  disapproveProduct (productId, data) {
    return $axios.put(`/adv/products/${productId}/sendStatusUpdate`, data)
  }
}

export default new ProductsService()
